import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { SEOService } from './services/seo.service';
import { SiteSettingsService } from './services/siteSettings.service';
import { UserService } from './services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private siteSettingsService: SiteSettingsService,
    private translate: TranslateService,
    private seoService: SEOService,
    private userService: UserService,
  ) {}

  loadTranslation() {
    return new Promise<void>((res) => {
      const locale = this.siteSettingsService.locale$.getValue();
      const availableLanguages = this.siteSettingsService.availableLanguages$.getValue();

      this.translate.addLangs(availableLanguages);
      this.translate.setDefaultLang(locale);
      this.seoService.updateLanguage(locale);

      this.translate.use(locale).pipe(first()).subscribe(() => res());
    });
  }

  downloadInitialData() {
    return Promise.all([this.loadTranslation(), this.userService.load()]);
  }
}
