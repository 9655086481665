import { Injectable } from '@angular/core';
import { SiteSettingsService as AbstractSiteSettingsService } from '@sitemule/ng-components/abstract-services/site-settings.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAddress } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService extends AbstractSiteSettingsService {
  override currency$ = new BehaviorSubject('DKK');
  override locale$ = new BehaviorSubject('da');
  availableLanguages$ = new BehaviorSubject(['da']);

  override siteName$ = new BehaviorSubject(environment.clientDetails.name);
  formSubmitEmail$ = new BehaviorSubject(environment.formSubmitEmail);

  primaryAddress$ = new BehaviorSubject(environment.clientDetails as IAddress);

  emailTemplateConfig$ = new BehaviorSubject(environment.emailTemplateConfig);

  constructor() {
    super();
  }
}

// TODO: Get all these info from server
