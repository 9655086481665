import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { IGNORE_AUTHORIZED_CHECK, IGNORE_TOAST_ON_STATUS } from '../interceptors/http-interceptor.interceptor';

const mitIDUrl = `${location.protocol}//${location.hostname}/utils/public/auth/mitid`;

// Uncomment this to work on localhost
// const token = '<token>'
// document.cookie = `tkn=${token};Path=/;`;
// const mitIDUrl = `https://min.tjlaan.dk/utils/public/auth/mitid`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  isLoggedIn$ = new BehaviorSubject(false);
  idValidated$ = new BehaviorSubject(false);
  error$ = new BehaviorSubject('');

  token$ = new BehaviorSubject('');

  load(): Promise<any> {
    return new Promise<void>((resolve) => {
      // Call any service, if it returns error we are not logged in
      // If 406, we are logged in but need CPR validation
      // If 401, we are clearly not logged in
      this.http.get<void>('/mtl/account', {
        context: new HttpContext().set(IGNORE_AUTHORIZED_CHECK, true).set(IGNORE_TOAST_ON_STATUS, [404])
      }).subscribe({
        next: () => {
          if (!this.isLoggedIn$.getValue()) {
            this.isLoggedIn$.next(true);
          }
          if (!this.idValidated$.getValue()) {
            this.idValidated$.next(true);
          }
          resolve();
        },
        error: (e: HttpErrorResponse) => {
          if (e.status === 401) {
            if (this.isLoggedIn$.getValue()) {
              this.isLoggedIn$.next(false);
            }
            if (this.idValidated$.getValue()) {
              this.idValidated$.next(false);
            }
          }
          else if (e.status === 406) {
            if (!this.isLoggedIn$.getValue()) {
              this.isLoggedIn$.next(true);
            }
            if (this.idValidated$.getValue()) {
              this.idValidated$.next(false);
            }
          }
          else if (e.status === 404) {
            if (!this.isLoggedIn$.getValue()) {
              this.isLoggedIn$.next(true);
            }
            if (!this.idValidated$.getValue()) {
              this.idValidated$.next(true);
            }
            this.error$.next(e.error.messageId);
          }
          else {
            throw new Error(`Unknown status code: ${e.status}`);
          }

          resolve();
        },
      });
    });
  }

  login(redirect_uri = location.href) {
    location.replace(
      `${mitIDUrl}/login${redirect_uri ? `?redirect_uri=${redirect_uri}` : ''}`
    );
  }
  logout() {
    return this.http.get<void>(`${mitIDUrl}/logout`).pipe(
      tap(() => {
        this.idValidated$.next(false);
        this.isLoggedIn$.next(false);
      })
    );
  }
  validateCpr(cpr: string) {
    return this.http.post<void>(
      `/mtl/validateCPR`,
      { cpr },
      {
        context: new HttpContext().set(IGNORE_TOAST_ON_STATUS, [404])
      }
    ).pipe(
      tap(() => {
        if (!this.idValidated$.getValue()) {
          this.idValidated$.next(true);
        }
      }),
    )
  };
}
