import {
  HttpContextToken, HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as sessionStorage from '@sitemule/core/utils/sessionStorage';
import { ToastService } from '@sitemule/ng-components/components/toast';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../services/user.service';

const serverReferenceKey = '_srvref';
const params = new URLSearchParams(document.location.search);
const serverReference = params.get(serverReferenceKey);

if (serverReference) {
  sessionStorage.setItem(serverReferenceKey, serverReference);
}


const PERMANENT_TOAST_IGNORE_STATUS = [401, 406]; // ['Unauthorized', 'Missing CPR']
export const IGNORE_TOAST_ON_STATUS = new HttpContextToken<number[]>(() => []);
export const IGNORE_AUTHORIZED_CHECK = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const userService = this.injector.get(UserService);
    const token = userService && userService.token$.getValue();
    let modifiedReq = request;
    const serverReferenceFromSession = sessionStorage.getItem(serverReferenceKey);

    if (serverReferenceFromSession) {
      modifiedReq = modifiedReq.clone({
        url: modifiedReq.url,
        params: modifiedReq.params.append(serverReferenceKey, serverReferenceFromSession)
      });
    }

    if (token) {
      modifiedReq = modifiedReq.clone({
        headers: modifiedReq.headers.set('Authorization', `Bearer ${token}`),
      });
    }
    return next
      .handle(modifiedReq)
      .pipe(
        catchError((error) => {
          const ignoreAuthorizedCheck = modifiedReq.context.get(IGNORE_AUTHORIZED_CHECK);
          if (error.status === 401 && !ignoreAuthorizedCheck) {
            const userService = this.injector.get(UserService);

            if (userService) {
              userService.login();
            }
          }
          else {
            const toast = this.injector.get(ToastService);
            const ignoreToast = [...PERMANENT_TOAST_IGNORE_STATUS, ...modifiedReq.context.get(IGNORE_TOAST_ON_STATUS)].indexOf(error.status) > -1;

            if (!ignoreToast && toast) {
              toast.push({
                type: 'warning',
                title: error.statusText,
                message: error.error?.message || error.message,
                autoHide: true
              });
            }
          }

          return throwError(() => error);
        })
      );
  }
}
