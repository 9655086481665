import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from './guards/loggedin.guard';
import { UserErrorGuard } from './guards/user-error.guard';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/loan-overview',
    pathMatch: 'full',
  },
  {
    path: 'loan-overview',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/loan-overview/loan-overview.module').then(
        (mod) => mod.LoanOverviewModule
      ),
  },
  {
    path: 'user-error/:errorCode',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./views/user-error/user-error.module').then(
        (mod) => mod.UserErrorModule
      ),
  },
  {
    path: 'cpr-validation',
    loadChildren: () =>
      import('./views/cpr-validation/cpr-validation.module').then(
        (mod) => mod.CPRValidationModule
      ),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./views/logout/logout.module').then(
        (mod) => mod.LogoutModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./views/login-aborted/login-aborted.module').then(
        (mod) => mod.LoginAbortedModule
      ),
  },
  {
    path: '**',
    canActivate: [LoggedInGuard, UserErrorGuard],
    loadChildren: () =>
      import('./views/404/404.module').then((mod) => mod.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
