import { CurrencyPipe, registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import da from '@angular/common/locales/da';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/ng-components/abstract-services/site-settings.service';
import { ToastModule, ToastService } from '@sitemule/ng-components/components/toast';
import { AddAcceptLanguageHttpInterceptor } from '@sitemule/ng-components/http-interceptors/add-accept-language';
import { HttpErrorInterceptor as BaseHttpErrorInterceptor } from '@sitemule/ng-components/http-interceptors/log-error';
import { RetryHttpInterceptor } from '@sitemule/ng-components/http-interceptors/retry-http';
import { CustomCurrencyPipe } from '@sitemule/ng-components/pipes/custom-currency';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppErrorHandler } from './error-handlers/app.error-handler';
import { HttpErrorInterceptor } from './interceptors/http-interceptor.interceptor';
import { SiteSettingsService } from './services/siteSettings.service';

registerLocaleData(da);

class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Object> {
    return this.http.get<any>(`/mnu/txt/listTextTranslation/${environment.translationKey}/${lang}`).pipe(map(res => {
      if (environment.translationKey === 'mintjl') {
        return res;
      }

      const ret : any = {};
      const reg = new RegExp(`^${environment.translationKey}\\.`);
      Object.keys(res).forEach(key => {
        const val = res[key];
        ret[key.replace(reg, 'mintjl.')] = val;
      });

      return ret;
    }));
  }
}

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log(`Missing translation for: ${params.key}`);
    return params.key;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper,
      },
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateHttpLoader,
        deps: [HttpClient],
      },
    }),
    ToastModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => () =>
        appService.downloadInitialData(),
      deps: [AppService],
      multi: true,
    },
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptLanguageHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    CurrencyPipe,
    CustomCurrencyPipe,
    ToastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
